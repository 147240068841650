import React, { createContext, useContext, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { DODInstructions } from "../solana/program/instructions";
import GameConnection from "./gameconnection";
import { PublicKey } from "@solana/web3.js";
import { createMatch } from "./gameLogic";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

const MESSAGE_TYPES = {
  SEARCHING: "SEARCHING",
  MATCH_FOUND: "MATCH_FOUND",
};

const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const { connected, publicKey } = useWallet();
  const anchorWallet = useAnchorWallet();
  const [dodInstance, setDodInstance] = useState(null);
  const [userInitialized, setUserInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gameConnection, setGameConnection] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [matchStatus, setMatchStatus] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [balance, setBalance] = useState(0);
  const [initialMatchData, setInitialMatchData] = useState(null);

  const updateMatchStates = (status, match, gameStatus) => {
    setTransactionStatus(status);
    setCurrentMatch(match);
    setMatchStatus(gameStatus);
  };

  const handleWebSocketMessage = (message) => {
    console.log("Handling websocket message:", message);

    switch (message.type) {
      case MESSAGE_TYPES.SEARCHING:
        setMatchStatus("searching");
        break;

      case MESSAGE_TYPES.MATCH_FOUND:
        handleMatchFound(message.match);
        break;

      default:
        console.log("Unknown message type:", message.type);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered with:", {
      anchorWallet,
      connected,
      publicKey,
    });

    let mounted = true; // Add mounted flag

    if (anchorWallet && connected && publicKey) {
      try {
        console.log("Creating new DOD instance...");
        const instance = new DODInstructions(anchorWallet);
        console.log("DOD instance created:", instance);
        if (mounted) {
          setDodInstance(instance);
          console.log("DOD instance set in state");

          // Existing functionality
          instance
            .getUserBalance(publicKey)
            .then((newBalance) => {
              if (mounted) {
                console.log("User balance fetched:", newBalance);
                setBalance(newBalance);
              }
            })
            .catch(console.error);

          checkUserInitialization(instance);

          const connection = new GameConnection();
          console.log("GameConnection created, attempting connection...");
          connection.connect(publicKey.toString(), handleWebSocketMessage);
          setGameConnection(connection);
          console.log("GameConnection established");

          return () => {
            console.log("Cleanup triggered - disconnecting");
            mounted = false;
            connection.disconnect();
          };
        }
      } catch (error) {
        console.error("Failed to initialize DODInstance:", error);
      }
    } else {
      console.log("Missing requirements:", {
        anchorWallet,
        connected,
        publicKey,
      });
      setDodInstance(null);
      setUserInitialized(false);
      if (gameConnection) {
        gameConnection.disconnect();
        setGameConnection(null);
      }
    }

    return () => {
      mounted = false;
    };
  }, [anchorWallet, connected, publicKey]);

  // Keep the tracking effect
  useEffect(() => {
    console.log("dodInstance state updated:", dodInstance);
  }, [dodInstance]);

  // [Rest of your existing functions remain exactly the same]
  const cancelSearch = () => {
    if (gameConnection) {
      gameConnection.cancelMatch(publicKey.toString());
      setMatchStatus(null);
      setError(null);
      setCurrentMatch(null);
    }
  };

  const resetGameState = (forceNewConnection = false) => {
    setMatchStatus(null);
    setCurrentMatch(null);
    setError(null);
    setLoading(false);
    setInitialMatchData(null);
    setTransactionStatus(null);

    if (forceNewConnection && gameConnection) {
      gameConnection.disconnect();
      const newConnection = new GameConnection();
      newConnection.connect(publicKey.toString(), handleWebSocketMessage);
      setGameConnection(newConnection);
    }
  };

  const updateBalance = async () => {
    if (dodInstance && connected && publicKey) {
      try {
        const newBalance = await dodInstance.getUserBalance(publicKey);
        setBalance(newBalance);
        return newBalance;
      } catch (error) {
        console.error("Failed to update balance:", error);
        throw error;
      }
    }
  };

  const checkUserInitialization = async (instance) => {
    try {
      setLoading(true);
      setError(null);

      const [userPDA] = instance.getUserPDA(publicKey);
      try {
        await instance.program.account.userAccount.fetch(userPDA);
        setUserInitialized(true);
      } catch {
        setUserInitialized(false);
      }
    } catch (err) {
      console.error("Failed to check user initialization:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const initializeUserIfNeeded = async () => {
    if (!userInitialized && dodInstance) {
      try {
        setLoading(true);
        await dodInstance.initializeUser();
        setUserInitialized(true);
      } catch (err) {
        console.error("Failed to initialize user:", err);
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMatchFound = async (match) => {
    const isPlayerA = match.player1 === publicKey.toString();
    const opponentPubkey = new PublicKey(
      isPlayerA ? match.player2 : match.player1
    );

    let instanceToUse = dodInstance;
    if (!instanceToUse && anchorWallet && connected && publicKey) {
      console.log("Attempting to recover DOD instance...");
      instanceToUse = new DODInstructions(anchorWallet);
      setDodInstance(instanceToUse);
      console.log("DOD instance recovered:", instanceToUse);
    }

    if (!instanceToUse) {
      throw new Error("DOD Instance is null - wallet may have disconnected");
    }

    try {
      //setLoading(true);
      //updateMatchStates("pending", null, "found");
      setInitialMatchData({
        player1: match.player1,
        player2: match.player2,
      });
      updateMatchStates("pending", null, "found");

      if (isPlayerA) {
        console.log("Player A: Executing transaction...");
        const matchResult = await instanceToUse.executeMatch(
          opponentPubkey,
          getBetAmountFromTier(match.betTier),
          instanceToUse.generateVRFSeed()
        );

        if (!matchResult) {
          throw new Error("Transaction failed or returned invalid result");
        }

        console.log("Player A: Transaction completed, selecting video...");
        const gameMatch = await createMatch(match.betTier, {
          matchId: match.matchId,
          isPlayerA: true,
          opponent: opponentPubkey.toString(),
          winner: matchResult.winner === "player1" ? "A" : "B",
        });

        if (
          !gameMatch.videoOutcome ||
          !gameMatch.playerAChoice ||
          !gameMatch.playerBChoice
        ) {
          throw new Error("Invalid video data in match creation");
        }
        //updateMatchStates("confirmed", gameMatch, "waiting");

        console.log("Player A: Video selected", {
          headline: gameMatch.headline,
          outcome: gameMatch.videoOutcome,
          winner: matchResult.winner,
        });

        const finalPlayerRole =
          matchResult.winner === "player1"
            ? gameMatch.videoOutcome
            : gameMatch.videoOutcome === "A"
            ? "B"
            : "A";

        console.log("Player A: Role assigned", {
          isWinner: matchResult.winner === "player1",
          role: finalPlayerRole,
          videoOutcome: gameMatch.videoOutcome,
        });

        updateMatchStates(
          "confirmed",
          {
            ...gameMatch,
            playerRole: finalPlayerRole,
          },
          "countdown"
        );
      } else {
        console.log("Player B: Starting balance check...");
        const initialBalance = await instanceToUse.getUserBalance(publicKey);
        updateMatchStates("pending", null, "found");

        const maxPollTime = 30000;
        const startTime = Date.now();

        const pollForOutcome = async () => {
          const currentBalance = await instanceToUse.getUserBalance(publicKey);
          if (currentBalance !== initialBalance) {
            const didWin = currentBalance > initialBalance;
            console.log("Player B: Balance changed", {
              initial: initialBalance,
              current: currentBalance,
              didWin,
            });

            const gameMatch = await createMatch(match.betTier, {
              matchId: match.matchId,
              isPlayerA: false,
              opponent: opponentPubkey.toString(),
              winner: didWin ? "B" : "A",
            });

            //updateMatchStates("confirmed", gameMatch, "waiting");

            if (
              !gameMatch.videoOutcome ||
              !gameMatch.playerAChoice ||
              !gameMatch.playerBChoice
            ) {
              throw new Error("Invalid video data in match creation");
            }

            console.log("Player B: Video selected", {
              headline: gameMatch.headline,
              outcome: gameMatch.videoOutcome,
              didWin,
            });

            const finalPlayerRole = didWin
              ? gameMatch.videoOutcome
              : gameMatch.videoOutcome === "A"
              ? "B"
              : "A";

            console.log("Player B: Role assigned", {
              didWin,
              role: finalPlayerRole,
              videoOutcome: gameMatch.videoOutcome,
            });

            updateMatchStates(
              "confirmed",
              {
                ...gameMatch,
                playerRole: finalPlayerRole,
              },
              "countdown"
            );
            return true;
          }

          if (Date.now() - startTime > maxPollTime) {
            throw new Error("Transaction confirmation timeout");
          }

          return false;
        };

        const pollInterval = setInterval(async () => {
          try {
            const outcomeFound = await pollForOutcome();
            if (outcomeFound) {
              clearInterval(pollInterval);
            }
          } catch (error) {
            clearInterval(pollInterval);
            setError(error.message);
            updateMatchStates("failed", null, null);
          }
        }, 1000);

        return () => clearInterval(pollInterval);
      }
    } catch (error) {
      console.error("[Match Flow] Match execution failed:", error);
      updateMatchStates("failed", null, null);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const searchForMatch = async (betTier) => {
    if (!gameConnection || !publicKey || !dodInstance) {
      setError("Missing required connection. Please reconnect wallet.");
      return;
    }

    try {
      setLoading(true);
      setMatchStatus("searching");

      const balance = await dodInstance.getUserBalance(publicKey);
      const betAmount = getBetAmountFromTier(betTier);

      if (balance < betAmount) {
        throw new Error("Insufficient balance for selected arena");
      }

      gameConnection.searchMatch(publicKey.toString(), betTier);
    } catch (error) {
      console.error("Match search failed:", error);
      setError(error.message);
      setMatchStatus(null);
    } finally {
      setLoading(false);
    }
  };

  const getBetAmountFromTier = (betTier) => {
    const amounts = {
      plankton: 0.005,
      fish: 0.5,
      whale: 5,
    };
    return amounts[betTier];
  };

  // Add to existing context functions
  const handleNextMatch = async () => {
    try {
      console.log("Starting next match process...");

      const currentTier = currentMatch.amount;

      // 1. Cancel current match
      await cancelSearch();

      // 2. Clean reset of game states
      resetGameState();

      // 3. Verify connections are valid
      if (
        !gameConnection?.ws ||
        gameConnection.ws.readyState !== WebSocket.OPEN
      ) {
        throw new Error("WebSocket connection invalid");
      }

      if (!dodInstance) {
        throw new Error("DodInstance not available");
      }

      // 4. Small delay to ensure state cleanup
      await new Promise((resolve) => setTimeout(resolve, 100));

      // 5. Start new search with existing connections
      console.log("Starting new search with tier:", currentTier);
      await searchForMatch(currentTier);
    } catch (error) {
      console.error("Failed to start next match:", error);
      // On critical connection error, force complete refresh
      if (error.message.includes("connection invalid")) {
        window.location.reload();
      } else {
        resetGameState(true); // Force new connection on error
      }
    }
  };

  return (
    <GameContext.Provider
      value={{
        dodInstance,
        userInitialized,
        loading,
        error,
        connected,
        publicKey,
        initializeUserIfNeeded,
        searchForMatch,
        currentMatch,
        balance,
        updateBalance,
        matchStatus,
        cancelSearch,
        resetGameState,
        initialMatchData,
        handleNextMatch,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
