import * as anchor from "@project-serum/anchor";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import idl from "./idl.json";
import { AnchorProvider } from "@project-serum/anchor";
import { Keypair } from "@solana/web3.js";
import bs58 from "bs58";

// Add this constant (replace with your actual secret key)
const AUTHORITY_KEYPAIR = Keypair.fromSecretKey(
  bs58.decode(
    "5mAAfmPbpJg99mt2HhHAyo4ijoWStXpS3ZqDpEHLbDnhBXstzq3khRdme61PkUtQWERdrQH9sx6Sa8UwMTf73VPp"
  )
);

const TREASURY_SEED = "treasury_v2";
const USER_SEED = "user";
const FEE_WALLET = new PublicKey(
  "JB9mFCv9sj83HYEFNK22b27uAPGAA8qzAcAvhmx4ekjw"
);
const PROGRAM_ID = new PublicKey(
  "BpkAipuapQzvCPPNM9TtHqEa676xF1ks3f3fmDhvWfyq"
);
const DEVNET_RPC_URL = "https://api.devnet.solana.com";

export class DODInstructions {
  constructor(wallet, programId = PROGRAM_ID) {
    this.connection = new Connection(DEVNET_RPC_URL);
    this.wallet = wallet;
    const provider = new anchor.AnchorProvider(this.connection, wallet, {
      commitment: "confirmed",
      preflightCommitment: "confirmed",
    });
    this.program = new anchor.Program(idl, programId, provider);
  }

  getTreasuryPDA() {
    return PublicKey.findProgramAddressSync(
      [Buffer.from(TREASURY_SEED)],
      this.program.programId
    );
  }

  getUserPDA(userPublicKey) {
    if (!userPublicKey) {
      throw new Error("User public key is required for PDA generation");
    }

    console.log("Getting user PDA for:", userPublicKey.toString());

    return PublicKey.findProgramAddressSync(
      [Buffer.from(USER_SEED), userPublicKey.toBuffer()],
      this.program.programId
    );
  }

  async waitForTransaction(signature) {
    try {
      const latestBlockHash = await this.connection.getLatestBlockhash();
      await this.connection.confirmTransaction(
        {
          signature,
          blockhash: latestBlockHash.blockhash,
          lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        },
        "confirmed"
      ); // Add this second parameter
    } catch (error) {
      console.error("Transaction confirmation failed:", error);
      throw error;
    }
  }

  //async initializeTreasury() {
  // try {
  // const [treasuryPDA] = this.getTreasuryPDA();

  // const tx = await this.program.methods
  //   .initializeTreasury()
  //   .accounts({
  //    treasury: treasuryPDA,
  //     payer: this.wallet.publicKey,
  //    systemProgram: SystemProgram.programId,
  //  })
  //   .rpc();

  //  await this.waitForTransaction(tx);
  //   return tx;
  // } catch (error) {
  //  console.error("Treasury initialization failed:", error);
  // throw error;
  // }
  //}

  async initializeAndDeposit(amount) {
    try {
      if (!this.wallet?.publicKey) {
        throw new Error("Wallet not connected");
      }

      const [treasuryPDA] = this.getTreasuryPDA();
      const [userPDA] = this.getUserPDA(this.wallet.publicKey);

      // Check if user account exists
      let userExists = false;
      try {
        await this.program.account.userAccount.fetch(userPDA);
        userExists = true;
      } catch (error) {
        console.log("User account doesn't exist");
      }

      let tx;
      if (!userExists) {
        // When user doesn't exist, use initializeUser as base method
        tx = await this.program.methods
          .initializeUser()
          .accounts({
            userAccount: userPDA,
            user: this.wallet.publicKey,
            systemProgram: SystemProgram.programId,
          })
          .postInstructions([
            await this.program.methods
              .deposit(new anchor.BN(amount))
              .accounts({
                user: this.wallet.publicKey,
                feeAccount: FEE_WALLET,
                treasury: treasuryPDA,
                userAccount: userPDA,
                systemProgram: SystemProgram.programId,
              })
              .instruction(),
          ])
          .rpc();
      } else {
        // Just send deposit instruction if user exists
        tx = await this.program.methods
          .deposit(new anchor.BN(amount))
          .accounts({
            user: this.wallet.publicKey,
            feeAccount: FEE_WALLET,
            treasury: treasuryPDA,
            userAccount: userPDA,
            systemProgram: SystemProgram.programId,
          })
          .rpc();
      }

      await this.waitForTransaction(tx);
      console.log("Transaction completed successfully");
      return tx;
    } catch (error) {
      console.error("Initialize and deposit failed:", error);
      throw error;
    }
  }

  async deposit(amount) {
    try {
      const [treasuryPDA] = this.getTreasuryPDA();
      const [userPDA] = this.getUserPDA(this.wallet.publicKey);

      const tx = await this.program.methods
        .deposit(new anchor.BN(amount))
        .accounts({
          user: this.wallet.publicKey,
          feeAccount: FEE_WALLET,
          treasury: treasuryPDA,
          userAccount: userPDA,
          systemProgram: SystemProgram.programId,
        })
        .rpc();

      await this.waitForTransaction(tx);
      return tx;
    } catch (error) {
      console.error("Deposit failed:", error);
      throw error;
    }
  }

  async executeMatch(opponent, betAmount, vrfSeed) {
    try {
      console.log("[Transaction Flow] Starting match execution");
      console.log(
        "[Transaction Flow] Player wallet:",
        this.wallet.publicKey.toString()
      );
      console.log("[Transaction Flow] Opponent wallet:", opponent.toString());
      console.log("[Transaction Flow] Bet amount:", betAmount);

      const betLamports = betAmount * anchor.web3.LAMPORTS_PER_SOL;
      const [treasuryPDA] = this.getTreasuryPDA();
      const [player1PDA] = this.getUserPDA(this.wallet.publicKey);
      const [player2PDA] = this.getUserPDA(opponent);

      // Log initial balances
      const player1Balance = await this.getUserBalance(this.wallet.publicKey);
      const player2Balance = await this.getUserBalance(opponent);

      console.log("[Transaction Flow] Initial balances:");
      console.log("Player 1 balance:", player1Balance);
      console.log("Player 2 balance:", player2Balance);

      if (player1Balance < betLamports || player2Balance < betLamports) {
        throw new Error("Insufficient balance for one or both players");
      }

      const transaction = await this.program.methods
        .executeMatch(new anchor.BN(betLamports), Array.from(vrfSeed))
        .accounts({
          player1Account: player1PDA,
          player2Account: player2PDA,
          feeAccount: FEE_WALLET,
          treasury: treasuryPDA,
          systemProgram: SystemProgram.programId,
        })
        .transaction();

      // Add compute budget instruction first
      transaction.instructions = [
        anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
          units: 400_000,
        }),
        ...transaction.instructions,
      ];

      const latestBlockhash = await this.connection.getLatestBlockhash();
      transaction.recentBlockhash = latestBlockhash.blockhash;
      transaction.feePayer = AUTHORITY_KEYPAIR.publicKey;

      // Sign with fee payer only
      transaction.sign(AUTHORITY_KEYPAIR);

      const signature = await this.connection.sendRawTransaction(
        transaction.serialize(),
        {
          skipPreflight: true,
          maxRetries: 5,
          preflightCommitment: "confirmed",
        }
      );

      await this.waitForTransaction(signature);

      // After transaction
      const player1BalanceAfter = await this.getUserBalance(
        this.wallet.publicKey
      );
      const player2BalanceAfter = await this.getUserBalance(opponent);

      console.log("[Transaction Flow] Final balances:");
      console.log("Player 1 final balance:", player1BalanceAfter);
      console.log("Player 2 final balance:", player2BalanceAfter);

      const result = {
        tx: signature,
        winner: player1BalanceAfter > player1Balance ? "player1" : "player2",
        player1: this.wallet.publicKey.toString(),
        player2: opponent.toString(),
      };

      console.log("[Transaction Flow] Match result:", result);
      return result;
    } catch (error) {
      console.error("[Transaction Flow] Match execution failed:", error);
      throw error;
    }
  }

  async withdraw() {
    try {
      if (!this.wallet?.publicKey) {
        throw new Error("Wallet not connected");
      }

      const [treasuryPDA] = this.getTreasuryPDA();
      const [userPDA] = this.getUserPDA(this.wallet.publicKey);

      // Verify user account exists
      try {
        await this.program.account.userAccount.fetch(userPDA);
      } catch (error) {
        throw new Error("User account not found");
      }

      const tx = await this.program.methods
        .withdraw() // matches IDL name exactly
        .accounts({
          user: this.wallet.publicKey,
          feeAccount: FEE_WALLET,
          treasury: treasuryPDA,
          userAccount: userPDA,
          systemProgram: SystemProgram.programId,
        })
        .signers([this.wallet.payer])
        .rpc(); // Remove skipPreflight option

      await this.waitForTransaction(tx);
      console.log("Withdrawal completed successfully");
      return tx;
    } catch (error) {
      console.error("Withdrawal failed:", error);
      throw error;
    }
  }

  async getUserBalance(userPublicKey) {
    try {
      const [userPDA] = this.getUserPDA(userPublicKey);
      const userAccount = await this.program.account.userAccount.fetch(
        userPDA,
        "confirmed" // Add this commitment level
      );
      return userAccount.balance.toNumber();
    } catch (error) {
      console.error("Failed to fetch user balance:", error);
      throw error;
    }
  }

  generateVRFSeed() {
    const randomBytes = new Uint8Array(32);
    crypto.getRandomValues(randomBytes);
    return randomBytes;
  }
}
