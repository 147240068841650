import React, { useState, useEffect, useRef } from "react";
import { QUOTES } from "./quotes.js";
import { useGame } from "./gameContext";
import { audioManager } from "./audioManager";
import BoxChoice from "./BoxChoice.jsx";
import { useSpring, animated } from "react-spring";
import "./GameDisplay.css";
import { getStoredStreak, updateStreak } from "./StreakManager";
import doubledImage from "../assets/images/doubled2.png";
import dustImage from "../assets/images/dust3.png";

const OutcomeBoxes = ({
  playerRole,
  playerAChoice,
  playerBChoice,
  stage,
  showYouIndicator,
}) => (
  <div className={`outcome-options ${stage}`}>
    <div className="option-container">
      <div
        className={`option ${playerRole === "A" ? "your-choice" : ""} ${
          stage !== "complete" && stage !== "final" ? "blacked-out" : ""
        }`}
        data-outcome="A"
      >
        {playerAChoice}
      </div>
      {showYouIndicator && playerRole === "A" && (
        <div className="you-indicator">YOU</div>
      )}
    </div>

    <span className="vs">vs</span>

    <div className="option-container">
      <div
        className={`option ${playerRole === "B" ? "your-choice" : ""} ${
          stage !== "complete" && stage !== "final" ? "blacked-out" : ""
        }`}
        data-outcome="B"
      >
        {playerBChoice}
      </div>
      {showYouIndicator && playerRole === "B" && (
        <div className="you-indicator">YOU</div>
      )}
    </div>
  </div>
);

const GameDisplay = ({
  currentMatch,
  playerRole,
  matchOutcome,
  onMatchComplete,
  isGameActive,
}) => {
  const [countdown, setCountdown] = useState(5);
  const [showVideo, setShowVideo] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showNextMatch, setShowNextMatch] = useState(false); // Add this
  const {
    updateBalance,
    matchStatus,
    error,
    cancelSearch,
    publicKey,
    initialMatchData,
    handleNextMatch,
    searchForMatch, // Add this
    resetGameState, // Add this
    getBetAmountFromTier, // Use existing utility function
  } = useGame();
  const videoRef = useRef(null);
  const [showMatchElements, setShowMatchElements] = useState(false);
  const [winStreak, setWinStreak] = useState(() =>
    getStoredStreak(publicKey?.toString())
  );
  const randomQuote = useRef(
    QUOTES[Math.floor(Math.random() * QUOTES.length)]
  ).current;

  useEffect(() => {
    console.log("Match state updated:", {
      playerRole: currentMatch?.playerRole,
      videoOutcome: currentMatch?.videoOutcome,
      transactionStatus: currentMatch?.transactionStatus,
    });

    if (
      currentMatch?.playerRole &&
      currentMatch?.transactionStatus === "confirmed"
    ) {
      const isCorrectRole = ["A", "B"].includes(currentMatch.playerRole);
      if (!isCorrectRole) {
        console.error("Invalid player role assigned:", currentMatch.playerRole);
      }
    }
  }, [currentMatch]);

  // Add this useEffect near your other effects
  useEffect(() => {
    if (!isGameActive) {
      setShowMatchElements(false);
    }
  }, [isGameActive]);

  useEffect(() => {
    if (videoEnded && currentMatch?.videoOutcome) {
      const timer = setTimeout(() => {
        setShowNextMatch(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [videoEnded, currentMatch?.videoOutcome]);

  // Reset countdown when match becomes active
  useEffect(() => {
    if (isGameActive && currentMatch?.transactionStatus === "confirmed") {
      setCountdown(5);
    }
  }, [isGameActive, currentMatch]);

  useEffect(() => {
    if (!isGameActive) return;

    if (currentMatch?.transactionStatus === "confirmed" && countdown > 0) {
      audioManager.play(countdown === 1 ? "countdownFinal" : "countdownTick");
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setShowVideo(true);
      if (videoRef.current) {
        videoRef.current
          .play()
          .catch((e) => console.error("Video play error:", e));
      }
    }
  }, [countdown, currentMatch, isGameActive]);

  useEffect(() => {
    if (videoEnded && currentMatch?.videoOutcome) {
      const isWinner = currentMatch.videoOutcome === currentMatch.playerRole;
      audioManager.play(isWinner ? "doubled" : "dust");
    }
  }, [videoEnded]);

  useEffect(() => {
    if (videoEnded && currentMatch?.videoOutcome && publicKey) {
      const isWinner = currentMatch.videoOutcome === currentMatch.playerRole;
      const newStreak = updateStreak(publicKey.toString(), isWinner, winStreak);
      // Add safety check to prevent infinite counting
      if (newStreak <= winStreak + 1) {
        setWinStreak(newStreak);
      }
    }
  }, [videoEnded, currentMatch, publicKey]);

  if (!isGameActive) {
    return null;
  }

  const shortenAddress = (address) => {
    return address ? address.slice(0, 6) : "";
  };

  // Add handler for stage changes
  const handleStageChange = (stage) => {
    if (stage === "complete") {
      setShowMatchElements(true);
    }
  };

  const handleVideoEnd = async () => {
    setVideoEnded(true);
    await updateBalance();
    // Remove the automatic onMatchComplete call
    // The cleanup will now happen through handleNextMatch or when user leaves manually
  };

  // Error State
  if (error) {
    return (
      <div className="countdown-display">
        <h2>Match Error: {error}</h2>
        <p>Please try again</p>
        <button onClick={onMatchComplete} className="retry-button">
          Return to Home
        </button>
      </div>
    );
  }

  // Searching State
  if (matchStatus === "searching") {
    return (
      <div className="countdown-display">
        <h2>Searching for opponent...</h2>
        <p className="random-quote">{randomQuote}</p>
        <button
          onClick={() => {
            cancelSearch();
            onMatchComplete();
          }}
          className="cancel-button"
        >
          Cancel Search
        </button>
      </div>
    );
  }

  // After the "searching" state check and before the "found" state check
  if (matchStatus === "waiting") {
    return (
      <div className="countdown-display">
        <h2>Preparing your match...</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  // Match Found & Transaction Pending State
  if (
    matchStatus === "found" ||
    currentMatch?.transactionStatus === "pending"
  ) {
    const userAddress =
      initialMatchData?.player1 === publicKey?.toString()
        ? initialMatchData?.player1
        : initialMatchData?.player2;
    const opponentAddress =
      initialMatchData?.player1 === publicKey?.toString()
        ? initialMatchData?.player2
        : initialMatchData?.player1;

    return (
      <div className="countdown-display">
        <h2>Opponent Found!</h2>
        <div className="wallet-display">
          <div className="wallet-pair">
            <span className="wallet-label">You</span>
            <span className="wallet-address">
              {shortenAddress(userAddress)}
            </span>
          </div>
          <span className="vs">vs</span>
          <div className="wallet-pair">
            <span className="wallet-label">Opponent</span>
            <span className="wallet-address">
              {shortenAddress(opponentAddress)}
            </span>
          </div>
        </div>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  if (
    matchStatus === "countdown" &&
    currentMatch?.transactionStatus === "confirmed"
  ) {
    return (
      <div className="countdown-display">
        <h2>Match starting in: {countdown}</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  // Add this check before the final video display return
  if (!currentMatch || !currentMatch.headline) {
    return (
      <div className="countdown-display">
        <h2>Preparing your match...</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  return (
    <div className="game-display">
      {showMatchElements && (
        <h2 className="match-headline">{currentMatch.headline}</h2>
      )}

      {!showVideo ? (
        <BoxChoice
          playerRole={currentMatch.playerRole}
          playerAChoice={currentMatch.playerAChoice}
          playerBChoice={currentMatch.playerBChoice}
          onRevealComplete={() => setShowVideo(true)}
          OutcomeBoxes={({ ...props }) =>
            showMatchElements ? <OutcomeBoxes {...props} /> : null
          }
          onStageChange={handleStageChange}
        />
      ) : (
        <>
          <OutcomeBoxes
            playerRole={currentMatch.playerRole}
            playerAChoice={currentMatch.playerAChoice}
            playerBChoice={currentMatch.playerBChoice}
            stage="final"
            showYouIndicator={true}
          />

          <video
            ref={videoRef}
            autoPlay
            playsInline
            webkit-playsinline="true"
            preload="auto"
            volume={0.99}
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "0 auto",
            }}
            onEnded={handleVideoEnd}
          >
            <source src={currentMatch.videoUrl} type="video/mp4" />
          </video>
          {videoEnded && currentMatch?.videoOutcome && (
            <div
              className={`outcome-overlay ${
                currentMatch.videoOutcome === currentMatch.playerRole
                  ? "win"
                  : "lose"
              }`}
            >
              {currentMatch.videoOutcome === currentMatch.playerRole ? (
                <img
                  src={doubledImage}
                  alt="DOUBLED"
                  className="doubled-image"
                />
              ) : (
                <img src={dustImage} alt="DUST" className="dust-image" />
              )}
            </div>
          )}
          {videoEnded && showNextMatch && (
            <div className="next-match-overlay">
              <button
                className={`next-match-button ${
                  currentMatch.videoOutcome === currentMatch.playerRole
                    ? "winner"
                    : "loser"
                }`}
                onClick={async () => {
                  try {
                    await handleNextMatch();
                  } catch (error) {
                    console.error("Next match error:", error);
                  }
                }}
              >
                <span className="primary-text">
                  {currentMatch.videoOutcome === currentMatch.playerRole
                    ? "PLAY AGAIN"
                    : "PLAY AGAIN"}
                </span>
                <span className="subtext">
                  {currentMatch.videoOutcome === currentMatch.playerRole
                    ? winStreak > 0
                      ? `Win streak: ${winStreak}`
                      : ""
                    : `Play next match`}
                </span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GameDisplay;
