// streakManager.js
export const getStoredStreak = (walletAddress) => {
    try {
      const streakData = localStorage.getItem(`streak_${walletAddress}`);
      if (!streakData) return 0;
      const { streak } = JSON.parse(streakData);
      return streak;
    } catch {
      return 0;
    }
  };
  
  export const saveStreak = (walletAddress, streak) => {
    try {
      localStorage.setItem(
        `streak_${walletAddress}`,
        JSON.stringify({
          streak,
          timestamp: Date.now(),
        })
      );
    } catch (error) {
      console.error("Failed to save streak:", error);
    }
  };
  
  export const updateStreak = (walletAddress, isWinner, currentStreak) => {
    const newStreak = isWinner ? currentStreak + 1 : 0;
    saveStreak(walletAddress, newStreak);
    return newStreak;
  };
  