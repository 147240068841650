import React, { useState, useRef } from "react";
import planktonLogo from "../assets/images/plankton.png";
import fishLogo from "../assets/images/angel-fish.png";
import whaleLogo from "../assets/images/whale.png";
import "./ArenaSelection.css";

// Import your sound files
import { melodySequence } from "../assets/sounds/ArenaButtonsLevels";

const ArenaSelection = ({ onArenaSelect }) => {
  const [selectedArena, setSelectedArena] = useState(null);
  const [currentSoundIndex, setCurrentSoundIndex] = useState(0);
  const audioRef = useRef(null);

  const arenas = [
    { name: "plankton", amount: 0.005, image: planktonLogo },
    { name: "fish", amount: 0.5, image: fishLogo },
    { name: "whale", amount: 5, image: whaleLogo },
  ];

  const handleArenaClick = (arena) => {
    setSelectedArena(arena);

    // Play current sound and advance to next
    if (audioRef.current) {
      audioRef.current.src = melodySequence[currentSoundIndex];
      audioRef.current
        .play()
        .catch((err) => console.error("Audio playback failed:", err));

      // Update sound index, loop back to 0 if at end
      setCurrentSoundIndex((prevIndex) =>
        prevIndex + 1 >= melodySequence.length ? 0 : prevIndex + 1
      );
    }
  };

  const handleConfirm = () => {
    if (selectedArena) {
      onArenaSelect(selectedArena.amount);
    }
  };

  return (
    <div className="arena-selection-container">
      <audio ref={audioRef} preload="auto" />
      <h2 className="arena-title">Choose Your Arena</h2>
      <p className="arena-subtitle">
        Select how much SOL to play with per match
      </p>

      <div className="arenas-grid">
        {arenas.map((arena) => (
          <div key={arena.name} className="arena-option">
            <button
              className={`arena-button ${
                selectedArena?.name === arena.name ? "selected" : ""
              }`}
              onClick={() => handleArenaClick(arena)}
            >
              <img src={arena.image} alt={arena.name} className="arena-image" />
              <span className="arena-button-amount">{arena.amount} SOL</span>
            </button>
          </div>
        ))}
      </div>

      {selectedArena && (
        <button className="confirm-button" onClick={handleConfirm}>
          [Confirm]
        </button>
      )}
    </div>
  );
};

export default ArenaSelection;
