import React, { useState, useEffect } from "react";
import "./BoxChoice.css";
import dLogo from "../assets/images/d-logo.png"; // Add this import

const BoxChoice = ({
  playerRole,
  playerAChoice,
  playerBChoice,
  onRevealComplete,
  OutcomeBoxes,
  onStageChange,
}) => {
  const [stage, setStage] = useState("initial");
  const [selectedBox, setSelectedBox] = useState(null);
  const [canSelect, setCanSelect] = useState(true);
  const [selectedOutcome, setSelectedOutcome] = useState(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [countdownTime, setCountdownTime] = useState(10);

  // Add effect to notify parent of stage changes
  useEffect(() => {
    if (onStageChange) {
      onStageChange(stage);
    }
  }, [stage]);

  // Debug logging
  useEffect(() => {
    console.log("Current stage:", stage);
    console.log("Player role:", playerRole);
    console.log("Selected box:", selectedBox);
  }, [stage, playerRole, selectedBox]);

  // Split into two separate useEffects for clearer control flow
  // First useEffect handles the countdown
  useEffect(() => {
    if (stage === "initial" && countdownTime > 0) {
      const timer = setInterval(() => {
        setCountdownTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [stage, countdownTime]);

  // Second useEffect handles the auto-select when time runs out
  useEffect(() => {
    if (
      countdownTime === 0 &&
      !selectedBox &&
      canSelect &&
      stage === "initial"
    ) {
      const randomChoice = Math.random() < 0.5 ? "up" : "down";
      handleBoxSelect(randomChoice);
    }
  }, [countdownTime, selectedBox, canSelect, stage]);

  const getPlayerOutcome = () => {
    // If player is role A, they should see playerAChoice
    // If player is role B, they should see playerBChoice
    return playerRole === "A" ? playerAChoice : playerBChoice;
  };

  const handleBoxSelect = (box) => {
    if (!canSelect || stage !== "initial") return;

    console.log("Box selected:", box);
    setSelectedBox(box);
    setCanSelect(false);

    // Always set the predetermined outcome regardless of box choice
    const predeterminedOutcome = getPlayerOutcome();
    setSelectedOutcome(predeterminedOutcome);

    setStage("revealing");

    setTimeout(() => {
      setStage("complete");
      setTimeout(() => {
        onRevealComplete();
      }, 2000);
    }, 1000);
  };

  return (
    <div className="box-choice">
      <div className="outcomes-container">
        <OutcomeBoxes
          playerRole={playerRole}
          playerAChoice={playerAChoice}
          playerBChoice={playerBChoice}
          stage={stage}
          showYouIndicator={stage === "complete"}
        />
      </div>

      <div className="boxes-container">
        <div
          className={`choice-box up ${
            selectedBox === "up" ? "selected" : ""
          } ${stage}`}
          onClick={() => handleBoxSelect("up")}
        >
          {selectedBox === "up" && stage !== "initial" ? (
            <span className="outcome-text">{selectedOutcome}</span>
          ) : (
            <img
              src={dLogo}
              alt="Choice"
              className="box-logo"
              onLoad={() => {
                console.log("Logo loaded");
                setLogoLoaded(true);
              }}
            />
          )}
        </div>

        <div
          className={`choice-box down ${
            selectedBox === "down" ? "selected" : ""
          } ${stage}`}
          onClick={() => handleBoxSelect("down")}
        >
          {selectedBox === "down" && stage !== "initial" ? (
            <span className="outcome-text">{selectedOutcome}</span>
          ) : (
            <img src={dLogo} alt="Choice" className="box-logo" />
          )}
        </div>

        {!selectedBox && stage === "initial" && logoLoaded && (
          <div className="choice-section">
            <div className="choice-prompt pixelated">Choose your box!</div>
            <div
              className={`countdown-timer pixelated ${
                countdownTime <= 3 ? "final" : ""
              }`}
            >
              {countdownTime}s
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoxChoice;
