import a1 from "./a1.mp3";
import a2 from "./a2.mp3";
import a3 from "./a3.mp3";
import a4 from "./a4.mp3";
import a5 from "./a5.mp3";
import a6 from "./a6.mp3";
import a7 from "./a7.mp3";
import a8 from "./a8.mp3";
import a9 from "./a9.mp3";
import a10 from "./a10.mp3";
import a11 from "./a11.mp3";
import a12 from "./a12.mp3";
import a13 from "./a13.mp3";
import a14 from "./a14.mp3";
import a15 from "./a15.mp3";
import a16 from "./a16.mp3";
import a17 from "./a17.mp3";
import a18 from "./a18.mp3";
import a19 from "./a19.mp3";
import a20 from "./a20.mp3";
import a21 from "./a21.mp3";
import a22 from "./a22.mp3";
import a23 from "./a23.mp3";
import a24 from "./a24.mp3";
import a25 from "./a25.mp3";
import a26 from "./a26.mp3";
import a27 from "./a27.mp3";
import a28 from "./a28.mp3";

export const melodySequence = [
  a1,
  a2,
  a3,
  a4,
  a5,
  a6,
  a7,
  a8,
  a9,
  a10,
  a11,
  a12,
  a13,
  a14,
  a15,
  a16,
  a17,
  a18,
  a19,
  a20,
  a21,
  a22,
  a23,
  a24,
  a25,
  a26,
  a27,
  a28,
];
