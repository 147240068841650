// gameLogic.js
function importAll(r) {
  let videos = {};
  r.keys().forEach((item) => {
    videos[item.replace("./", "")] = r(item);
  });
  return videos;
}
// Import all MP4 files from the videos directory
const videoFiles = importAll(
  require.context("../assets/videos", false, /\.mp4$/)
);

// Add the console.log here to verify video imports
console.log("Available videos:", videoFiles);

// Sample video data with clear win conditions
const videos = [
  {
    url: videoFiles["deeneygoal.mp4"],
    headline: "Which team scores first?",
    outcomeA: "Blue",
    outcomeB: "Yellow",
    outcome: "B",
  },
  {
    url: videoFiles["nipple.mp4"],
    headline: "How many nipples does he have?",
    outcomeA: "Other than 2",
    outcomeB: "2",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20get%20in%20the%20car.mp4`,
    headline: "Does she get in the car??",
    outcomeA: "gets in",
    outcomeB: "doesnt get in",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20make%20it%20to%20the%20other%20side.mp4`,
    headline: "Does he get to the other side?",
    outcomeA: "gets to the other side",
    outcomeB: "stops",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20soda%20fall%20over.mp4`,
    headline: "Does the soda fall over?",
    outcomeA: "falls over",
    outcomeB: "stays up",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20bumble%20bee%20get%20KO'd%20yes.mp4`,
    headline: "Does bumble bee get KO'd?",
    outcomeA: "gets ko'd",
    outcomeB: "is fine",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20gramps%20fall%20over%20no.mp4`,
    headline: "Does gramps fall off the swing?",
    outcomeA: "falls",
    outcomeB: "lands it",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20find%20the%20zyn.mp4`,
    headline: "Does she find the zyn?",
    outcomeA: "no zyn found",
    outcomeB: "finds the zyn",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20run%20his%20fingers%20through%20the%20hair%20on%20her%20head.mp4`,
    headline: "Does he run his fingers through the hair on her head?",
    outcomeA: "yes",
    outcomeB: "no",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20get%20wet.mp4`,
    headline: "Does she get wet?",
    outcomeA: "got moist",
    outcomeB: "stayed dry",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20bear%20win.mp4`,
    headline: "Does the bear lose?",
    outcomeA: "bear KO's opposition ",
    outcomeB: "bear gets KO'd",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20get%20messed%20up%20by%20a%20shark.mp4`,
    headline: "Does she get messed up by a shark?",
    outcomeA: "doesnt get fugged up by shark",
    outcomeB: "shark fugged her",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20walk%20on%20the%20path.mp4`,
    headline: "Does he walk on the path?",
    outcomeA: "walks on the path",
    outcomeB: "doesnt walk on the path",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20enjoy%20the%20balcony%20in%20peace%20no.mp4`,
    headline: "Does he enjoy the balcony in peace?",
    outcomeA: "no",
    outcomeB: "yes",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20spisey%20save%20all%20the%20characters.mp4`,
    headline: "Does spidy save character?",
    outcomeA: "saves them all",
    outcomeB: "lets a few die",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/do%20they%20only%20catch%20fish%20no.mp4`,
    headline: "Did they only catch fish?",
    outcomeA: "fish + other animals",
    outcomeB: "only fish",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20toss%20the%20rice.mp4`,
    headline: "Did he toss the rice?",
    outcomeA: "tossed",
    outcomeB: "didnt toss",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/are%20they%20strangers%20no.mp4`,
    headline: "Is the man a stranger to the girl?",
    outcomeA: "not a stranger",
    outcomeB: "stranger",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20ball%20go%20into%20the%20glass.mp4`,
    headline: "Does the ball go into the glass?",
    outcomeA: "didn't go in",
    outcomeB: "ball went in",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20get%20the%20chips.mp4`,
    headline: "Does he get the chips?",
    outcomeA: "got them",
    outcomeB: "no",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20come%20back%20down.mp4`,
    headline: "Does he come back down?",
    outcomeA: "flew away",
    outcomeB: "safe landing",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20fall%20over%20no.mp4`,
    headline: "Does he fall over?",
    outcomeA: "stayed up",
    outcomeB: "fell over",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20boot%20of%20the%20car%20hit%20the%20wall.mp4`,
    headline: "Does the boot of the car hit the wall?",
    outcomeA: "hit the wall",
    outcomeB: "no",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/do%20the%20tourists%20get%20attacked%20no.mp4`,
    headline: "Do the tourists get bitten/attacked?",
    outcomeA: "no",
    outcomeB: "got bit",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20air%20plane%20get%20hit.mp4`,
    headline: "Does the plane get hit?",
    outcomeA: "got hit",
    outcomeB: "no",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20kid%20break%20the%20wood.mp4`,
    headline: "Does the kid break the block ?",
    outcomeA: "breaks the block",
    outcomeB: "bitched it",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20knock%20over%20all%20the%20pins.mp4`,
    headline: "Do they knock all the pins over in 2 shots?",
    outcomeA: "missed it",
    outcomeB: "hit all pins",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20get%20a%20hole%20in%20one%20yes.mp4`,
    headline: "Does he get a hole in 1?",
    outcomeA: "yes",
    outcomeB: "missed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20boat%20crash.mp4`,
    headline: "Does the boat crash?",
    outcomeA: "crashed",
    outcomeB: "saved it",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20bird%20fly.mp4`,
    headline: "Does the bird die?",
    outcomeA: "it fly's",
    outcomeB: "fell to ground",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20hit%20the%20target.mp4`,
    headline: "Does he hit the target?",
    outcomeA: "misses it",
    outcomeB: "hit it",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20snow%20boarder%20fail.mp4`,
    headline: "Does the snowboarder fall?",
    outcomeA: "lands it",
    outcomeB: "got wrecked",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20fall%20in%20no.mp4`,
    headline: "Does he fall in?",
    outcomeA: "falls in",
    outcomeB: "too fast to fall",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20get%20caught%20by%20the%20cops%20no.mp4`,
    headline: "Does he get caught by the cops?",
    outcomeA: "escaped",
    outcomeB: "gets arrested",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20tv%20break.mp4`,
    headline: "Does the tv break?",
    outcomeA: "smashed it",
    outcomeB: "didnt break",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20get%20attacked%20at%20all%20yes.mp4`,
    headline: "Does he get attacked at all?",
    outcomeA: "gets attacked",
    outcomeB: "doesnt get attacked",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20bomb%20go%20off.mp4`,
    headline: "Does the bomb actually go off?",
    outcomeA: "doesnt",
    outcomeB: "explodes",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/is%20there%20a%20condom%20in%20the%20wallet.mp4`,
    headline: "Is there a condom in the wallet?",
    outcomeA: "doesnt have a condom",
    outcomeB: "has a condom",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/is%20her%20hand%20going%20purple.mp4`,
    headline: "Is her hand going purple?",
    outcomeA: "hand is purple",
    outcomeB: "hands are fine",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20small%20guy%20win%20in%20practice.mp4`,
    headline: "Does the small guy win in the practice?",
    outcomeA: "got fugged up",
    outcomeB: "won",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20jump%20scare%20his%20girlfriend.mp4`,
    headline: "Does he jump scare his girlfriend?",
    outcomeA: "she got scared",
    outcomeB: "epic fail",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/which%20direction%20did%20the%20milk%20spill.mp4`,
    headline: "Which direction did the milk spill?",
    outcomeA: "right",
    outcomeB: "left",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/is%20there%20a%20racoon%20in%20the%20draw.mp4`,
    headline: "What is hiding in the drawer?",
    outcomeA: "domestic creature (ie cat)",
    outcomeB: "wild creature",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20get%20a%20trick%20shot.mp4`,
    headline: "Does she get a perfect trick shot?",
    outcomeA: "got it",
    outcomeB: "failed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20man%20escape.mp4`,
    headline: "Does the man escape?",
    outcomeA: "got stomped on",
    outcomeB: "escapes",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/which%20limb%20did%20the%20gorilla%20use%20first%20in%20the%20fight.mp4`,
    headline: "Which limb does the gorilla use first in the fight?",
    outcomeA: "arms",
    outcomeB: "legs",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/is%20the%20driver%20passed%20out.mp4`,
    headline: "Is the driver passed out?",
    outcomeA: "driver passed out",
    outcomeB: "driver is awake",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20win%20the%201v1.mp4`,
    headline: "Does he win the 1v1?",
    outcomeA: "won",
    outcomeB: "loss",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20eat%20no.mp4`,
    headline: "Does he eat?",
    outcomeA: "ate his food",
    outcomeB: "didnt eat",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20pass%20the%20test.mp4`,
    headline: "Does he pass the test?",
    outcomeA: "failed",
    outcomeB: "passed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20bro%20win%20yes.mp4`,
    headline: "Does bro win?",
    outcomeA: "fail",
    outcomeB: "won",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20make%20it%20over%20the%20bar.mp4`,
    headline: "Does she make it over the bar?",
    outcomeA: "made it over",
    outcomeB: "failed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20miss.mp4`,
    headline: "Does she miss?",
    outcomeA: "scored",
    outcomeB: "missed",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20save%20the%20ball.mp4`,
    headline: "Does she save the ball?",
    outcomeA: "conceeded a goal",
    outcomeB: "saved",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20make%20the%20jump.mp4`,
    headline: "Does he make the jump?",
    outcomeA: "made it",
    outcomeB: "falls",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20crash%20no.mp4`,
    headline: "Does he crash?",
    outcomeA: "didnt crash",
    outcomeB: "crashed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20number%209%20win%20the%20race.mp4`,
    headline: "Does number 9 win the race?",
    outcomeA: "won",
    outcomeB: "lost",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20skater%20with%20the%20stripes%20on%20his%20back%20win.mp4`,
    headline: "Does the skater with the stars on his back win?",
    outcomeA: "won",
    outcomeB: "lost",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20get%20interuppted%20mid%20photo.mp4`,
    headline: "Does she get interrupted mid photo?",
    outcomeA: "didn't get interrupted ",
    outcomeB: "got interrupted",
    outcome: "",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/which%20direction%20did%20the%20duck%20move.mp4`,
    headline: "Which direction did the duck move?",
    outcomeA: "right",
    outcomeB: "left",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20save%20the%20goal.mp4`,
    headline: "Does he save the goal?",
    outcomeA: "didnt save",
    outcomeB: "saved",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20she%20hurt%20herself.mp4`,
    headline: "Does she hurt herself?",
    outcomeA: "doesnt get hurt",
    outcomeB: "get's hurt",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/which%20row%20of%20cans%20fell%20first.mp4`,
    headline: "Which row of cans fell first?",
    outcomeA: "Bottom",
    outcomeB: "Top",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20fatty%20make%20the%20shot%20yes.mp4`,
    headline: "Does fatty make the shot?",
    outcomeA: "scored",
    outcomeB: "misses",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20orange%20win.mp4`,
    headline: "Does orange win the point?",
    outcomeA: "lost the point",
    outcomeB: "won the point",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20drop%20the%20ball%20no.mp4`,
    headline: "Does he drop the ball?",
    outcomeA: "caught it",
    outcomeB: "dropped it",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20white%20shirt%20win%20the%20point.mp4`,
    headline: "Does the white shirt win the point?",
    outcomeA: "lost the point",
    outcomeB: "won the point",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20make%20the%20half%20court%20shot.mp4`,
    headline: "Does the guy make the half court shot?",
    outcomeA: "missed it",
    outcomeB: "made it",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20ball%20get%20caught.mp4`,
    headline: "Does the ball get caught?",
    outcomeA: "caught",
    outcomeB: "dropped",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20fumble%20the%20touch%20down%20no.mp4`,
    headline: "Does blue fumble the touch down?",
    outcomeA: "fumbles it",
    outcomeB: "scored it",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20white%20score.mp4`,
    headline: "Does white score?",
    outcomeA: "scores",
    outcomeB: "missed",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/do%20red%20score%20no.mp4`,
    headline: "Do red score the goal?",
    outcomeA: "scores",
    outcomeB: "fails",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20the%20ice%20hokey%20player%20score.mp4`,
    headline: "Does the ice hockey player score?",
    outcomeA: "scores",
    outcomeB: "doesnt score",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20miss.mp4`,
    headline: "Does he miss?",
    outcomeA: "misses",
    outcomeB: "scores",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20black%20score%20no.mp4`,
    headline: "Does black score?",
    outcomeA: "misses",
    outcomeB: "scores",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20red%20score%20the%20touch%20down.mp4`,
    headline: "Does red score the touch down?",
    outcomeA: "doesnt score",
    outcomeB: "scores",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20he%20make%20it%20to%20the%20base%20successfully.mp4`,
    headline: "Does he successfully make it to the base?",
    outcomeA: "no",
    outcomeB: "yes",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20white%20score%20the%20touch%20down1.mp4`,
    headline: "Does white score the touch down?",
    outcomeA: "yes",
    outcomeB: "no",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20black%20score%20the%20touch%20down2.mp4`,
    headline: "Does black score the touch down?",
    outcomeA: "scores",
    outcomeB: "get's tackled",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20white%20win%20the%20fight%201.mp4`,
    headline: "Does white win the fight?",
    outcomeA: "white won",
    outcomeB: "white lost",
    outcome: "B",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20white%20get%20knocked%20out.mp4`,
    headline: "Does white get knocked out?",
    outcomeA: "no",
    outcomeB: "gets knocked out",
    outcome: "A",
  },
  {
    url: `https://dord-game-videos.b-cdn.net/DOD/does%20black%20win%20no.mp4`,
    headline: "Does black win?",
    outcomeA: "yes",
    outcomeB: "no",
    outcome: "B",
  },
  // Add more videos with similar structure
];

export const createMatch = async (amount, matchData = null) => {
  // Use matchId to seed video selection
  const videoIndex =
    parseInt(matchData.matchId.slice(0, 8), 16) % videos.length;
  const selectedVideo = videos[videoIndex];

  return {
    id: matchData.matchId,
    amount,
    winner: matchData.winner,
    videoUrl: selectedVideo.url,
    headline: selectedVideo.headline,
    playerAChoice: selectedVideo.outcomeA,
    playerBChoice: selectedVideo.outcomeB,
    videoOutcome: selectedVideo.outcome, // Include the video's predetermined outcome
  };
};

// Mock waiting period with countdown
export const waitForOpponent = async () => {
  return new Promise((resolve) => setTimeout(resolve, 2000)); // 2 second wait
};

// Determine if player is A or B
export const determinePlayerRole = () => {
  return Math.random() > 0.5 ? "A" : "B";
};

// Mock match execution
export const executeMatch = async (match) => {
  return match.winner; // Return predetermined winner
};
